import axios from "axios";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from "react-query";
import { Calendar, Credentials, TimeModel } from "./_models";
import axiosClient from "../../axiosClient";
import toast from "react-hot-toast";

const API_URL = process.env.REACT_APP_API_URL;

const getCalendarBySpace = async (spaceId: number): Promise<Calendar> => {
  const response = await axios.get(
    `${API_URL}/calendar/${spaceId}?date=2024-06-10`,
  );
  return response.data;
};

const getPasses = async (spaceId: number): Promise<Calendar> => {
  const response = await axios.get(
    `${API_URL}/calendar/${spaceId}?date=2024-06-10`,
  );
  return response.data;
};
const useGetPasses = ({ spaceId, date }) => {
  return useQuery({
    queryKey: [`calendar/${spaceId}`],
    queryFn: () =>
      axios
        .get(`${API_URL}/calendar/${spaceId}?date=2024-06-10`)
        .then((res) => res.data),
    enabled: !!spaceId,
  });
};

const getCalendarBySpaceReference = async (
  spaceRef: string,
): Promise<Calendar> => {
  const response = await axios.get(
    `${API_URL}/calendar/${spaceRef}?date=2024-06-10`,
  );
  return response.data;
};

const getAddOns = async (): Promise<Calendar> => {
  const response = await axios.get(`${API_URL}/add-ons`);
  return response.data;
};

const ClientLogin = async () => {
  const response = await axios.post(`${API_URL}/client-login`);
  return response.data;
};

const getClientSecret = async (): Promise<string> => {
  const response = await axios.post(`${API_URL}/checkout`);
  return response.data.clientSecret;
};

const useCreateOrders = () => {
  const queryClient = useQueryClient();

  const createOrdersMutation = useMutation({
    mutationFn: (values) => {
      return axios.post(`${API_URL}/orders/from-widget`, values);
    },
    onSuccess: (data) => {
      // @ts-ignore
      
         // @ts-ignore
      toast.success(data?.data?.message?.entitled)
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
    onError: (error) => {
      
      // @ts-ignore
      toast.error(error?.response?.data?.message)
     
    },
  });

  return createOrdersMutation;
};


const useCheckSpaceBeforeStore= () => {
  const queryClient = useQueryClient();

  const checkSpaceBeforeStore = useMutation({
    mutationFn: (values) => {
      return axios.post(`${API_URL}/orders/check-widget`, values);
    },
    onSuccess: (data) => {
      // @ts-ignore
      
         // @ts-ignore
      toast.success(data?.data?.message?.entitled)
      queryClient.invalidateQueries({ queryKey: ["orders"] });
    },
    onError: (error) => {
      
      // @ts-ignore
      toast.error(error?.response?.data?.message)
     
    },
  });

  return checkSpaceBeforeStore;
};
const login = async (credentials: Credentials) => {
  const response = await axios.post(`${API_URL}/client-login`, credentials);
  return response.data;
};

export const useLogin = () => {
  return useMutation(login);
};
const useGetTimes = (): UseQueryResult<TimeModel, Error> => {
  return useQuery<TimeModel, Error>({
    queryKey: ["times"],
    queryFn: () => axios.get(`${API_URL}/times`).then((res) => res.data),
  });
};
const useGetCalendarBySpace = ({
  roomId,
  date,
}: {
  roomId: string;
  date: string;
}) => {
  return useQuery({
    queryKey: ["calendar",date],
    queryFn: () =>
      axios
        .get(`${API_URL}/calendar/${roomId}/get?date=${date}`)
        .then((res) => res.data),
    enabled: !!roomId && !!date,
  });
};

const useGetSpacesById = ({
  roomId,
  withFilter,
}: {
  roomId: string;
  withFilter: string;
}) => {
  return useQuery({
    queryKey: ["calendar"],
    queryFn: () =>
      axios
        .get(`${API_URL}/spaces/show/${roomId}?${withFilter ? `with=${withFilter}` : ''}`)
        .then((res) => res.data),
    
  });
};
const useGetCalendarBySpaceWidget = ({
  roomId,
  date,
  time
}: {
  roomId: string;
  date: string;
  time: string;
}) => {
  return useQuery({
    // {{baseUrl}}/calendar/1/widget?date=2024-08-23&time=10:30 AM
    queryKey: ["calendar-widget",date,time],
    queryFn: () =>
      axios
        .get(`${API_URL}/calendar/${roomId}/get/widget?date=${date}${time?  `&time=${time}`:'' }`)
        .then((res) => res.data),
    enabled: !!roomId && !!date && !!time,
  });
};
const useGetClientsPasses = ({
  client,
  withFilter,
  loggedIn,
  availableDays,
}) => {
  return useQuery({
    queryKey: ["client-passes"],
    queryFn: () =>
      axiosClient
        .get(
          `client-passes?${withFilter ? `with=${withFilter}` : ""}${availableDays !== "" ? `&availableDays=${availableDays}` : ""}`,
        )
        .then((res) => res.data),
    enabled: !!loggedIn  ,
  });
};

const useGetStripePublicKey= () => {
  return useQuery({
    queryKey: [`stripe-public-key`],
    queryFn: () =>
      axios
        .get(`${API_URL}/stripe-public-key`)
        .then((res) => res.data),

  });
};
const useGetExtraData= () => {
  return useQuery({
    queryKey: [`extra-data`],
    queryFn: () =>
      axios
        .get(`${API_URL}/extra-data`)
        .then((res) => res.data),

  });
};


const useGetLabelsForAdvancedNotice= () => {
  return useQuery({
    queryKey: [`labels`],
    queryFn: () =>
      axios
        .get(`${API_URL}/labels/type/1`)
        .then((res) => res.data),

  });
};


export {
  getCalendarBySpace,
  getAddOns,
  useCreateOrders,
  getClientSecret,
  ClientLogin,
  getCalendarBySpaceReference,
  useGetTimes,
  useGetCalendarBySpace,
  useGetSpacesById,
  useGetPasses,
  useGetClientsPasses,
  useGetStripePublicKey,
  useGetExtraData,
  useGetCalendarBySpaceWidget,
  useCheckSpaceBeforeStore,
  useGetLabelsForAdvancedNotice
};
