import {
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFnsV3";
import { addMonths, subMonths, format, isSameMonth } from "date-fns";
import TimePicker from "./time-picker";
import TimeRangeValidation from "./time-range-validation";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import {
  calculateTimeDifference,
  extractStyles,
  GetHoursRate,
} from "../Pages/core/_utils";
import { selectedRangeProps } from "../Pages/core/_models";
import DayCard from "./day-card";
import TotalTimeSelectedCard from "./total-time-selected";
import moment from "moment";
import { useEffect, useState } from "react";

const CustomCalendarHeaderRoot = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  padding: "18px 16px",
  alignItems: "center",
});

const DateTimeSelection = ({
  day,
  hourRates,
  selectedUnits,
  availableTimeData,
  timeRanges,
  selectedRange,
  onResetDay,
  currentRange,
  setCurrentRange,
  resetSelectedRange,
  setDay,
  setTimeRanges,
  setSelectedRange,
  selectedHourRate,
  second_widget_color,
  main_widget_color,
  calendarSuccess,
  photos,
  detailsSpace,
  css_widget,
  productId,
  setDUnitId,
  convertTo24HourFormat
}: any) => {
  const handleChangeDay = (newDay: Date | null) => {
    setDay(newDay);
   
    const formattedDay = newDay ? format(newDay, "yyyy-MM-dd") : "";

    if (selectedRange && "reservation" in selectedRange) {
      const dayReservation = selectedRange.reservation.find(
        (reservation) => reservation.day === formattedDay
      );
      setTimeRanges(dayReservation ? dayReservation.range : []);
    }


    setCurrentRange({ start: "", end: "" });
  };
  const CustomCalendarHeader = (props) => {
    const { currentMonth, onMonthChange } = props;

    const selectNextMonth = () => onMonthChange(addMonths(currentMonth, 1));
    const selectPreviousMonth = () => onMonthChange(subMonths(currentMonth, 1));
    const isCurrentMonth = isSameMonth(currentMonth, new Date());

    return (
      <>
        <CustomCalendarHeaderRoot>
          <Stack alignContent={"end"} direction="row">
            {!isCurrentMonth && (
              <IconButton onClick={selectPreviousMonth} title="Previous month">
                <ChevronLeft />
              </IconButton>
            )}
          </Stack>
          <Stack spacing={0} direction="column" alignItems="center">
            <Typography
              variant="h4"
              sx={{ fontWeight: "normal" }}
              color={"#333333"}
              letterSpacing={1}
            >
              {format(currentMonth, "yyyy")}
            </Typography>
            <Typography variant="h6" sx={{ fontWeight: "bold" }}>
              {format(currentMonth, "MMMM")}
            </Typography>
          </Stack>
          <Stack spacing={1} direction="row">
            <IconButton onClick={selectNextMonth} title="Next month">
              <ChevronRight />
            </IconButton>
          </Stack>
        </CustomCalendarHeaderRoot>
        <Divider />
      </>
    );
  };

  const shouldDisableDate = (date: Date) => {
    const dayName = date.toLocaleString("en-US", { weekday: "long" });
    return !hourRates?.some((rate) => rate.day === dayName);
  };
  const handleStartChange = (event) => {
    setCurrentRange({ ...currentRange, start: event.target.value as string });
  };

  const handleEndChange = (event) => {
    setCurrentRange({ ...currentRange, end: event.target.value as string });
  };
  const handleSave = () => {
    if (currentRange.start && currentRange.end) {
      const newTimeRanges = [
        ...timeRanges,
        { start: currentRange.start, end: currentRange.end },
      ];
      handleSaveTimeRanges(newTimeRanges, "");
      setCurrentRange({ start: null, end: null });
    }
  };
  const dayOfWeekFormatter = (weekday) => {
    return format(weekday, "EE"); // 'EEE' gives you abbreviated day names like 'Mon', 'Tue'
  };
  const handleSaveTimeRanges = (
    timeRanges: { start: string; end: string }[],
    comment: string
  ) => {
    const formattedDay = day ? format(day, "yyyy-MM-dd") : "";

    const dayOfWeek = moment(day).format("dddd");
    setSelectedRange((prevSelectedRange: selectedRangeProps | {}) => {
      const selectedHourRate = GetHoursRate(hourRates, dayOfWeek);

      // Calculate totalHours, totalHoursPrice, totalAmount for each range
      const updatedRanges = timeRanges.map((range) => {
        if (selectedHourRate) {
          const timeDifference = calculateTimeDifference(
            range.start,
            range.end
          );
          const durationInHours =
            timeDifference.hours + timeDifference.minutes / 60;
          const totalHoursPrice =
            durationInHours * selectedHourRate.rate * selectedHourRate.rate_per;
          const totalAddonsPrice = 0; // Add your logic to calculate totalAddonsPrice if applicable
          const subTotalAmount = totalHoursPrice + totalAddonsPrice;
          const totalAmount = subTotalAmount; // Initially, totalAmount is same as subTotalAmount
          const totalDiscount = 0; // Leave totalDiscount empty or 0
          const unitHoursPrice =
            selectedHourRate.rate * selectedHourRate.rate_per;

          return {
            ...range,
            totalHours: durationInHours,
            totalHoursPrice,
            totalAddonsPrice,
            totalAmount,
            subTotalAmount,
            totalDiscount,
            selectedAddons: [], // Ensure selectedAddons is included
            unitHoursPrice,
          };
        }
        return {
          ...range,
          totalHours: 0,
          totalHoursPrice: 0,
          totalAddonsPrice: 0,
          totalAmount: 0,
          subTotalAmount: 0,
          totalDiscount: 0,
          selectedAddons: [], // Ensure selectedAddons is included
          unitHoursPrice: 0,
        };
      });

      let newReservation: selectedRangeProps["reservation"] = [];
      let newTotalHours = 0;
      let newTotalHoursPrice = 0;
      let newTotalAddonsPrice = 0;
      let newTotalDiscount = 0;
      let newSubTotalAmount = 0;

      if (prevSelectedRange && "reservation" in prevSelectedRange) {
        const existingDayIndex = prevSelectedRange.reservation.findIndex(
          (reservation) => reservation.day === formattedDay
        );

        if (existingDayIndex !== -1) {
          // Update the existing reservation
          newReservation = prevSelectedRange.reservation.map(
            (reservation, index) => {
              if (index === existingDayIndex) {
                const totalHours = updatedRanges.reduce(
                  (sum, range) => sum + range.totalHours,
                  0
                );
                const totalHoursPrice = updatedRanges.reduce(
                  (sum, range) => sum + range.totalHoursPrice,
                  0
                );
                const totalAddonsPrice = updatedRanges.reduce(
                  (sum, range) => sum + range.totalAddonsPrice,
                  0
                );
                const subTotalAmount = totalHoursPrice + totalAddonsPrice;
                const totalAmount = subTotalAmount; // Initially, totalAmount is same as subTotalAmount
                const totalDiscount = 0; // Leave totalDiscount empty or 0

                return {
                  ...reservation,
                  totalHours,
                  totalHoursPrice,
                  totalAddonsPrice,
                  totalDiscount,
                  range: updatedRanges,
                  selectedPasse: reservation.selectedPasse || null, // Ensure selectedPasse is included
                  totalAmount,
                  subTotalAmount,
                  comment, // Include the comment
                };
              }
              return reservation;
            }
          );
        } else {
          // Add new reservation
          const totalHours = updatedRanges.reduce(
            (sum, range) => sum + range.totalHours,
            0
          );
          const totalHoursPrice = updatedRanges.reduce(
            (sum, range) => sum + range.totalHoursPrice,
            0
          );
          const totalAddonsPrice = updatedRanges.reduce(
            (sum, range) => sum + range.totalAddonsPrice,
            0
          );
          const subTotalAmount = totalHoursPrice + totalAddonsPrice;
          const totalAmount = subTotalAmount; // Initially, totalAmount is same as subTotalAmount
          const totalDiscount = 0; // Leave totalDiscount empty or 0

          newReservation = [
            ...prevSelectedRange.reservation,
            {
              day: formattedDay,
              totalHours,
              totalHoursPrice,
              totalAddonsPrice,
              totalDiscount,
              range: updatedRanges,
              selectedPasse: null, // Ensure selectedPasse is included
              totalAmount,
              subTotalAmount,
              comment, // Include the comment
              // @ts-ignore
            },
          ];
        }

        // Aggregate values for the big parent object
        newTotalHours = newReservation.reduce(
          (sum, res) => sum + res.totalHours,
          0
        );
        newTotalHoursPrice = newReservation.reduce(
          (sum, res) => sum + res.totalHoursPrice,
          0
        );
        newTotalAddonsPrice = newReservation.reduce(
          (sum, res) => sum + res.totalAddonsPrice,
          0
        );
        newTotalDiscount = newReservation.reduce(
          (sum, res) => sum + res.totalDiscount,
          0
        );
        newSubTotalAmount = newReservation.reduce(
          (sum, res) => sum + res.subTotalAmount,
          0
        );
      } else {
        // No previous reservations
        const totalHours = updatedRanges.reduce(
          (sum, range) => sum + range.totalHours,
          0
        );
        const totalHoursPrice = updatedRanges.reduce(
          (sum, range) => sum + range.totalHoursPrice,
          0
        );
        const totalAddonsPrice = updatedRanges.reduce(
          (sum, range) => sum + range.totalAddonsPrice,
          0
        );
        const subTotalAmount = totalHoursPrice + totalAddonsPrice;
        const totalAmount = subTotalAmount; // Initially, totalAmount is same as subTotalAmount
        const totalDiscount = 0; // Leave totalDiscount empty or 0

        newReservation = [
          {
            day: formattedDay,
            totalHours,
            totalHoursPrice,
            totalAddonsPrice,
            totalDiscount,
            range: updatedRanges,
            selectedPasse: null, // Ensure selectedPasse is included
            totalAmount,
            subTotalAmount,
            comment, // Include the comment
            // @ts-ignore
          },
        ];

        // Aggregate values for the big parent object
        newTotalHours = newReservation[0].totalHours;
        newTotalHoursPrice = newReservation[0].totalHoursPrice;
        newTotalAddonsPrice = newReservation[0].totalAddonsPrice;
        newTotalDiscount = newReservation[0].totalDiscount;
        newSubTotalAmount = newReservation[0].subTotalAmount;
      }

      return {
        ...prevSelectedRange,
        totalAmount: newSubTotalAmount - newTotalDiscount, // Calculate totalAmount as sum of subTotalAmounts minus totalDiscount
        subTotalAmount: newSubTotalAmount,
        totalHours: newTotalHours,
        totalHoursPrice: newTotalHoursPrice,
        totalAddonsPrice: newTotalAddonsPrice,
        totalDiscount: newTotalDiscount,
        reservation: newReservation,
      };
    });

    setTimeRanges(timeRanges);
  };
  const isReserved = (time: string, row) => {

    return selectedUnits?.availability?.some((range) => {
 
      if (!range.available && range?.d_order_id !== null) {
        const startIndex = availableTimeData.findIndex(
          (t: any) => t.time === range.start
        );
        const endIndex = availableTimeData.findIndex(
          (t: any) => t.time === range.end
        );
        const currentIndex = availableTimeData.findIndex(
          (t: any) => t.time === time
        );

        return currentIndex >= startIndex && currentIndex <= endIndex;
      }
      return false;
    });
  };

  const [hfContainerStyles, setHfContainerStyles] = useState({});
  const [dateWrapStyles, setdateWrapStyles] = useState({});

  useEffect(() => {
    if (css_widget && typeof css_widget === "string") {
      const extractedStyles = extractStyles(css_widget, ".card-wrap");
      const extractedStylesTwo = extractStyles(css_widget, " .date-wrap");
      setHfContainerStyles(extractedStyles);
      setdateWrapStyles(extractedStylesTwo);
    } else {
    }
  }, [css_widget]);

  return (
    <Grid container columnSpacing={10} rowSpacing={2} mb={15}>
      <Grid item xs={12} md={5}>
        <Box mb={2}>
          {photos !== "" ? (
            <img src={photos} style={{ width: "100%" }} />
          ) : (
            <img
              src={`/assets/background/cowarkingSpace.png`}
              style={{ width: "100%" }}
            />
          )}
        </Box>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            dayOfWeekFormatter={dayOfWeekFormatter}
            displayStaticWrapperAs="desktop"
            value={day}
            disablePast
            onChange={handleChangeDay}
            shouldDisableDate={shouldDisableDate}
            slots={{ calendarHeader: CustomCalendarHeader }}
            sx={{
              paddingBottom: 5,
              width: "100%",
              "& .MuiDateCalendar-root": {
                width: "100%",
              },
              "& .MuiDayCalendar-header": {
                justifyContent: "space-evenly",
              },

              "& .Mui-selected": {
                backgroundColor: `${main_widget_color} !important `,
              },
              "& .MuiDayCalendar-weekContainer": {
                justifyContent: "space-evenly",
              },
            }}
          />
        </LocalizationProvider>

        <Divider />
      </Grid>
      <Grid item xs={12} md={7} sx={{ height: "100%", overflowY: "auto" }}>
        <DayCard day={day} onResetDay={onResetDay} css_widget={css_widget} />

        <TimePicker
          availableTimeData={availableTimeData}
          timeRanges={timeRanges}
          currentRange={currentRange}
          setCurrentRange={setCurrentRange}
          isReserved={isReserved}
          selectedHourRate={selectedHourRate}
          availability={selectedUnits?.availability}
          main_widget_color={main_widget_color}
          css_widget={css_widget}
          convertTo24HourFormat={convertTo24HourFormat}
        />
        {calendarSuccess && (
          <>
            {currentRange.start && (
              <TimeRangeValidation
                currentRange={currentRange}
                handleStartChange={handleStartChange}
                handleEndChange={handleEndChange}
                availableTimeData={availableTimeData}
                handleSave={handleSave}
                timeRanges={timeRanges}
                selectedRange={selectedRange}
                day={day}
                selectedUnits={selectedUnits}
                selectedHourRate={selectedHourRate}
                main_widget_color={main_widget_color}
                css_widget={css_widget}
                productId={productId}
                setDUnitId={setDUnitId}
              />
            )}
          </>
        )}

        {selectedRange?.reservation?.length > 0 && (
          <Box
            sx={{
              backgroundColor: "white",
              px: 0,
              py: 0,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mt: 4,
            }}
          >
            <Typography pl={5} variant="body2">
              Total Time Selected
            </Typography>
            {/*  */}

            <TotalTimeSelectedCard
              totalHoursSelected={selectedRange.totalHours}
              HourUnit={selectedRange?.reservation}
              resetSelectedRange={resetSelectedRange}
              css_widget={css_widget}
            />
          </Box>
        )}

        {selectedRange?.reservation?.length > 0 && (
          <Box mt={2}>
            <Typography>
              <Typography variant="body1" component="span" fontWeight="bold">
                Tip:
              </Typography>{" "}
              To add another booking, click on another time slot or date.
            </Typography>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default DateTimeSelection;
