import { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, styled } from "@mui/material";
import { useAuth } from "../hooks/useAuth";
import SignInForm from "./authForms/signinform";
import SignUpForm from "./authForms/signup";
import { extractStyles } from "../Pages/core/_utils";
import ForgetPasswordForm from "./authForms/forgetPassword";

const StyledLoginCard = styled(Card)(({ theme }) => ({
  height: "70%",
  boxShadow: "0px 4px 8px 8px rgba(183, 184, 184, 0.25)",
}));

const SignInSignUpPage = ({ activeStep, setActiveStep, main_widget_color, css_widget,productId,spaceId }) => {
  const [signInForm, setSignInForm] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState([]);
  const [signUpForm, setSignUpForm] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    repeat_password: "",
  });

  const [pageType, setPageType] = useState("signIn");
  const [userConnected, setUserConnected] = useState(false);
  const auth = useAuth();

  const handleChange = (formName, event) => {
    setSignInForm((prevValues) => ({
      ...prevValues,
      [formName]: event.target.value,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (auth.isLoggedIn) {
        setActiveStep(activeStep + 1);
      } else {
        const response = await auth.login(
          {
            email: signInForm.email,
            password: signInForm.password,
          },
          () => {
            setFormErrors({
              // @ts-ignore
              message: "email or Password is invalid",
            });
          }
        );

        if (response.success) {
          setUserConnected(true);
          // setActiveStep(activeStep + 1);
        } else {
          // Handle login failure (e.g., show error message)

        }
      }
    } catch (e) {
      // Handle unexpected errors
     
    }
  };

  const handleSignupUser = async () => {
    try {
      const data={
        ...signUpForm,
        d_space_id:spaceId
      }
      const response = await auth.register(data);
     
      if (response.success) {
        setActiveStep(activeStep + 1);
      } else {
        setFormErrors(response.message.response.data.errors)
        
      }
    } catch (e) {
      
    }
  };

  const handleSignUpChange = (formName, event) => {
    setSignUpForm((prevValues) => ({
      ...prevValues,
      [formName]: event.target.value,
    }));
  };
  const goToSignUp = () => {
    if (auth.isLoggedIn) {
      auth.logout();
    } else {
      setPageType("signUpPage");
    }
  };
  const goToLogin = () => {
    setPageType("signIn");
  };

  // *********************
  const [stylesOne, setStylesOne] = useState({});
  const [stylesTwo, setStylesTwo] = useState({});
  const [stylesThree, setStylesThree] = useState({});
  const [stylesBtnSignup, setStylesBtnSignup] = useState({});

  useEffect(() => {
    if (css_widget && typeof css_widget === 'string') {
      const extractedStyles = extractStyles(css_widget, '.card-wrap');
      const extractedStylesTwo = extractStyles(css_widget, '.login-card');
      const extractedStylesThree = extractStyles(css_widget, '.btn-login-wrap');
      const extractedStylesbtn = extractStyles(css_widget, '.btn-signup-card');
      setStylesOne(extractedStyles);
      setStylesTwo(extractedStylesTwo)
      setStylesThree(extractedStylesThree)
      setStylesBtnSignup(extractedStylesbtn)
    } else {
     
    }
  }, [css_widget]);

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "start" }}
    >
      <Box sx={{ width: "100%", maxWidth: 400, mx: "auto" }}>
        {pageType === "signIn" || pageType === "forrgetPassword" ? (
          <>
            <StyledLoginCard>
              <CardContent
                sx={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  ...stylesTwo,
                  ...stylesOne
                }}
              >
                {pageType === "signIn" && (
                  <SignInForm
                    signInForm={signInForm}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    userConnected={userConnected}
                    formErrors={formErrors}
                    main_widget_color={main_widget_color}
                    stylesThree={stylesThree}
                    setPageType={setPageType}
                  />
                )}
                {pageType === "forrgetPassword" && (
                  <ForgetPasswordForm
                  stylesThree={stylesThree}
                    handleChange={handleChange}
                    // handleSubmit={handleSubmit}
                    setPageType={setPageType}
                    main_widget_color={main_widget_color}


                  />
                )}
              </CardContent>
            </StyledLoginCard>
            {pageType !== "forrgetPassword" &&
              <Box
                sx={{ width: "100%", maxWidth: 400, mx: "auto", paddingTop: 2 }}>
                <Button variant="contained" sx={{
                  backgroundColor: main_widget_color !== null ? `${main_widget_color} !important ` : 'var(--primary-color)',
                  '&:hover': {
                    backgroundColor: main_widget_color !== null ? `${main_widget_color} !important ` : 'var(--primary-color)',
                  },
                  ...stylesBtnSignup
                }} onClick={goToSignUp} fullWidth>
                  {auth.isLoggedIn ? "Sign in as different user" : "Sign Up"}
                </Button>
              </Box>
            }
          </>
        ) : (
          <SignUpForm
            signUpForm={signUpForm}
            handleSignupUser={handleSignupUser}
            handleSignUpChange={handleSignUpChange}
            goToLogin={goToLogin}
            main_widget_color={main_widget_color}
            formErrors={formErrors}
            productId={productId}
          />
        )}
      </Box>
    </Box >
  );
};

export default SignInSignUpPage;
