//@ts-nocheck
import React, { useMemo, useState } from "react";
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import "../styles/payment-form.scss";
import { Box, CircularProgress, Grid, Typography } from "@mui/material";

import useResponsiveFontSize from "../useResponsiveFontSize";
import {
  useCheckSpaceBeforeStore,
  useCreateOrders,
} from "../Pages/core/_requests";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const PaymentForm = ({
  totalPrice,
  addOnsList,
  selectedRange,
  passId,
  passesData,
  productId,
  selectedUnits,
  main_widget_color,
  extraData,
  styleOne,
  stylesTwo,
  stylesThree,
  setIsBookingSuccess,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [loading, setLoading] = useState(false);

  const [isCardComplete, setIsCardComplete] = useState(false);
  const [isExpiryComplete, setIsExpiryComplete] = useState(false);
  const [isCvcComplete, setIsCvcComplete] = useState(false);
  const createOrderMutation = useCreateOrders();
  const checkOrderMutation = useCheckSpaceBeforeStore();

  const handleCheckSpace = async (event) => {
    console.log(
      selectedRange?.reservation[0]["special_request"],
      selectedRange?.reservation[0],
      selectedRange
    );

    event.preventDefault();
    const formData = new FormData();
    const data = {
      linked_to_pass: 0,
      default: 1,
      d_client_id: localStorage.getItem("client_id"),
      d_pass_id: passId,
      d_space_id: productId,
      payment_method: 1,
      special_request: selectedRange?.reservation[0]["special_request"] || "",
      first_amount: totalPrice,
      final_amount: totalPrice,

      order_times: selectedRange?.reservation,
    };

    let orderTimesRange = data?.order_times.reduce((acc, item) => {
      const rangesWithDate = (item?.range || []).map((range) => ({
        ...range,
        date: item?.day,
      }));
      return acc.concat(rangesWithDate);
    }, []);

    orderTimesRange.forEach((item, index) => {
      console.log(data?.special_request);
      
      formData.append(
        `orders[${index}][special_request]`,
       data?.special_request
      );
      formData.append(
        `orders[${index}][first_amount]`,
        data?.first_amount == null ? "" : data?.first_amount
      );
      formData.append(
        `orders[${index}][final_amount]`,
        data?.final_amount == null ? "" : data?.final_amount
      );
      formData.append(
        `orders[${index}][d_unit_id]`,
        selectedUnits == null ? "" : selectedUnits
      );
      formData.append(
        `orders[${index}][d_pass_id]`,
        data?.d_pass_id == null ? "" : data?.d_pass_id
      );
      formData.append(
        `orders[${index}][d_space_id]`,
        data?.d_space_id == null ? "" : data?.d_space_id
      );
      if (item.selectedAddons) {
        item.selectedAddons.forEach((addon, addonIndex) => {
          // Object.entries(addon).forEach(([key, value]) => {
          formData.append(
            `orders[${index}][add_ons][${addonIndex}][id]`,
            addon?.id
          );
          formData.append(
            `orders[${index}][add_ons][${addonIndex}][quantity]`,
            addon?.quantity
          );

          // })
        });
      }

      formData.append(`orders[${index}][order_times][0][end_time]`, item?.end);
      formData.append(
        `orders[${index}][order_times][0][start_time]`,
        item?.start
      );
      formData.append(`orders[${index}][order_times][0][date]`, item?.date);

      // Append other properties from item
    });

    try {
      await checkOrderMutation.mutateAsync(formData);
      handleSubmit();
    } catch (error) {}
  };
  const handleSubmit = async (event) => {
    setLoading(true);

    if (!stripe || !elements) {
      setLoading(false);
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardNumberElement),
    });
    setLoading(false);

    if (payload.error) {
    } else {
      const card = payload?.paymentMethod?.card;

      const PaymentId = payload?.paymentMethod?.id;
      if (card) {
        handleStoreBooking(
          card.exp_month,
          card.exp_year,
          card.last4,
          PaymentId
        );
      }
    }
  };

  const handleStoreBooking = async (exp_month, exp_year, last4, PaymentId) => {
    const formData = new FormData();
    const data = {
      card_number: last4,
      month: exp_month,
      year: exp_year,
      payment_method_id: PaymentId,
      linked_to_pass: 0,
      default: 1,
      d_client_id: localStorage.getItem("client_id"),
      d_pass_id: passId,
      d_space_id: productId,
      payment_method: 1,
      special_request: selectedRange?.reservation[0]["special_request"] || "",
      first_amount: totalPrice,
      final_amount: totalPrice,

      order_times: selectedRange?.reservation,
    };
    formData.append(`linked_to_pass`, data?.linked_to_pass);
    formData.append(`card_number`, data?.card_number);
    formData.append(`month`, data?.month);
    formData.append(`year`, data?.year);
    formData.append(`payment_method_id`, data?.payment_method_id);
    formData.append(`payment_reference`, PaymentId);
    formData.append(`default`, data?.default);
    formData.append(`d_client_id`, localStorage.getItem("client_id"));

    formData.append(`payment_method`, data?.payment_method);

    let orderTimesRange = data?.order_times.reduce((acc, item) => {
      const rangesWithDate = (item?.range || []).map((range) => ({
        ...range,
        date: item?.day,
      }));
      return acc.concat(rangesWithDate);
    }, []);

    orderTimesRange.forEach((item, index) => {
      formData.append(
        `orders[${index}][special_request]`,
        data?.special_request == null ? "" : data?.special_request
      );
      formData.append(
        `orders[${index}][first_amount]`,
        data?.first_amount == null ? "" : data?.first_amount
      );
      formData.append(
        `orders[${index}][final_amount]`,
        data?.final_amount == null ? "" : data?.final_amount
      );
      formData.append(
        `orders[${index}][d_unit_id]`,
        selectedUnits == null ? "" : selectedUnits
      );
      formData.append(
        `orders[${index}][d_pass_id]`,
        data?.d_pass_id == null ? "" : data?.d_pass_id
      );
      formData.append(
        `orders[${index}][d_space_id]`,
        data?.d_space_id == null ? "" : data?.d_space_id
      );
      if (item.selectedAddons) {
        item.selectedAddons.forEach((addon, addonIndex) => {
          // Object.entries(addon).forEach(([key, value]) => {
          formData.append(
            `orders[${index}][add_ons][${addonIndex}][id]`,
            addon?.id
          );
          formData.append(
            `orders[${index}][add_ons][${addonIndex}][quantity]`,
            addon?.quantity
          );

          // })
        });
      }

      formData.append(`orders[${index}][order_times][0][end_time]`, item?.end);
      formData.append(
        `orders[${index}][order_times][0][start_time]`,
        item?.start
      );
      formData.append(`orders[${index}][order_times][0][date]`, item?.date);

      // Append other properties from item
    });

    try {
      await createOrderMutation.mutateAsync(formData);
      setIsBookingSuccess(true);
    } catch (error) {}
  };
  return (
    <form
      className="payment-form"
      style={{ width: "100%", display: "flex", flexDirection: "column" }}
      onSubmit={handleCheckSpace}
    >
      <div className="billing-info">
        <span style={{ ...stylesThree, ...stylesTwo }}>
          Billing Information
        </span>
        <label style={{ marginTop: "1em" }}>
          Card number
          <CardNumberElement
            options={options}
            onChange={(event) => {
              setIsCardComplete(event.complete);
            }}
          />
        </label>
        <label>
          Expiration
          <CardExpiryElement
            options={options}
            onChange={(event) => {
              setIsExpiryComplete(event.complete);
            }}
          />
        </label>
        <label>
          CVC
          <CardCvcElement
            options={options}
            onChange={(event) => {
              setIsCvcComplete(event.complete);
            }}
          />
        </label>
      </div>
      <div className="total" style={{ width: "30vw" }}>
        <span>Total:</span>
        <span>${totalPrice.toFixed(2)}</span>
      </div>
      <div className="terms">
        <span>
          By clicking "Complete Order", you are agreeing to our{" "}
          <a href={extraData?.term_of_services_url}>terms of service</a> and{" "}
          <a href={extraData?.privacy_policy_url}>privacy policy</a>.
        </span>
      </div>
      {/* {stripe && isCardComplete && isExpiryComplete && isCvcComplete && ( */}
      <button
        className="payment-button"
        type="submit"
        style={{
          backgroundColor:
            main_widget_color !== null
              ? main_widget_color
              : "var(--primary-color)",
        }}
        // disabled={!stripe || !isCardComplete || !isExpiryComplete || !isCvcComplete || loading}
      >
        {createOrderMutation?.isLoading ? (
          <CircularProgress size={24} sx={{ color: "#fff" }} />
        ) : (
          "Complete Order"
        )}
      </button>
      <Box alignSelf={"center"}>
        <img
          src="/assets/ssl-ribbon.png"
          width={"97"}
          style={{ margin: "0 20px" }}
        />
        <img src="/assets/cc.png" width={"147"} style={{ margin: "0 20px" }} />
        <img src="/assets/ssl.png" width={"120"} style={{ margin: "0 20px" }} />
      </Box>
      {/* )} */}
    </form>
  );
};

export default PaymentForm;
