import React, { useEffect, useState } from "react";
import { Box, FormControl, MenuItem, Select, IconButton } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { extractStyles, formatDateToYYYYMMDD } from "../Pages/core/_utils";
import { useGetCalendarBySpaceWidget } from "../Pages/core/_requests";

// Utility function to convert time string to minutes since midnight
const timeToMinutes = (time) => {
  const [hours, minutes] = time.split(":");
  const isPM = time.includes("PM");
  const hour = parseInt(hours, 10);
  return (isPM && hour !== 12 ? hour + 12 : hour) * 60 + parseInt(minutes, 10);
};

// Utility function to find the index of a time in availableTimeData
const findTimeIndex = (time, availableTimeData) => {
  return availableTimeData.findIndex(t => t.time === time);
};

const TimeRangeValidation = ({
  currentRange,
  handleStartChange,
  handleEndChange,
  availableTimeData,
  handleSave,
  timeRanges,
  selectedRange,
  day,
  selectedUnits,
  selectedHourRate,
  main_widget_color,
  css_widget,
  productId,
  setDUnitId
}) => {

  const { data: calendarWidget, isSuccess: calendarWidgetSuccess } = useGetCalendarBySpaceWidget({
    roomId: productId as string,
    date: formatDateToYYYYMMDD(day),
    time: currentRange?.start
  });
  useEffect(() => {
    calendarWidget && calendarWidgetSuccess &&
      setDUnitId(calendarWidget?.data?.d_unit_id)
  }, [calendarWidget, calendarWidgetSuccess])

  const isTimeInRanges = (time) => {
    return timeRanges?.some((range) => {
      const startMinutes = timeToMinutes(range.start);
      const endMinutes = timeToMinutes(range.end);
      const timeMinutes = timeToMinutes(time);
      return timeMinutes >= startMinutes && timeMinutes <= endMinutes;
    });
  };

  const filterStartTimes = () => {
    if (!currentRange.start) return availableTimeData;

    const currentStartMinutes = timeToMinutes(currentRange.start);
    const availability = selectedUnits?.availability;

    const filteredStart = [];
    let stopAdding = false;

    for (const time of availableTimeData) {
      const timeMinutes = timeToMinutes(time.time);

      if (timeMinutes < currentStartMinutes) continue; // Skip times before the selected start time

      const isAvailable = availability?.some((slot) =>
        time.time === slot.start && slot.available
      );

      if (!isAvailable || isTimeInRanges(time.time)) {
        stopAdding = true;
      }

      if (!stopAdding) {
        filteredStart.push(time);
      } else {
        break; // Stop adding times if the conditions are met
      }
    }

    return filteredStart;
  };

  // const filterEndTimes = () => {
  //   if (!currentRange.start) return [];

  //   const currentStartIndex = findTimeIndex(currentRange.start, availableTimeData);
  //   const availability = selectedUnits.availability;

  //   const filteredEnd = [];
  //   let stopAdding = false;

  //   for (const time of availableTimeData.slice(currentStartIndex + 1)) {
  //     if (stopAdding) break; // Stop processing further times if a condition is met

  //     const isAvailable = availability.some((slot) => 
  //       time.time === slot.start && slot.available
  //     );

  //     if (!isAvailable || isTimeInRanges(time.time)) {
  //       stopAdding = true;
  //     }

  //     if (!stopAdding) {
  //       filteredEnd.push(time);
  //     } else {
  //       break; // Stop adding times if the conditions are met
  //     }
  //   }

  //   return filteredEnd;
  // };

  const filterEndTimes = () => {
    if (!currentRange.start) return [];

    const currentStartIndex = findTimeIndex(currentRange.start, availableTimeData);
    const availability = selectedUnits?.availability;

    const filteredEnd = [];
    let stopAdding = false;

    for (const time of availableTimeData.slice(currentStartIndex + 1)) {
      if (stopAdding) break; // Stop processing further times if a condition is met

      const isAvailable = availability?.some((slot) =>
        time.time === slot.start && slot.available
      );

      if (!isAvailable && isTimeInRanges(time.time)) {
        stopAdding = true;
      }

      if (!stopAdding) {
        filteredEnd.push(time);
      } else {
        break; // Stop adding times if the conditions are met
      }
    }

    // Adjust the filteredEnd array by slicing based on the selectedHourRate.rate_per
    const rateAdjustment = selectedHourRate.rate_per;
    return filteredEnd.slice(rateAdjustment);
  };

  const filteredStartData = filterStartTimes();
  const filteredEndData = filterEndTimes();


  const [stylesOne, setStylesOne] = useState({});
  const [stylesTwo, setStylesTwo] = useState({});

  useEffect(() => {
    if (css_widget && typeof css_widget === 'string') {
      const extractedStyles = extractStyles(css_widget, '.no-gutter');
      const extractedStylesTwo = extractStyles(css_widget, '.save-btn');
      setStylesOne(extractedStyles);
      setStylesTwo(extractedStylesTwo)
    } else {
      
    }
  }, [css_widget]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        ...stylesOne
      }}
      py={1}
      px={0}
    >
      <FormControl sx={{ flexGrow: 1, backgroundColor: "white" }}>
        <Select
          size="small"
          value={currentRange.start || ""}
          onChange={(e) => handleStartChange(e)}
          displayEmpty
        >
          {filteredStartData.map((time, index) => (
            <MenuItem key={index} value={time.time}>
              {time.time}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <EastIcon
        sx={{
          mx: 1,
          flexGrow: 1,
          fontWeight: "500",
          fontSize: "2rem",
          color: "gray",
        }}
      />

      <FormControl sx={{ m: 1, flexGrow: 1, backgroundColor: "white" }}>
        <Select
          size="small"
          value={currentRange.end || ""}
          onChange={(e) => handleEndChange(e)}
          displayEmpty
          disabled={!currentRange?.start}
        >
          {calendarWidget?.data?.times?.map((time, index) => (
            <MenuItem key={index} value={time.entitled}>
              {time.entitled}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <IconButton
        disabled={currentRange.start == null || currentRange.end == null}
        color="primary"
        onClick={handleSave}
        sx={{
          "&:hover": {
            backgroundColor: "transparent",
            boxShadow: "none",
          },

        }}
      >
        <AddBoxIcon sx={{ fontSize: "3rem", color: main_widget_color !== null ? `${main_widget_color} !important` : 'var(--primary-color)', ...stylesTwo }} />
      </IconButton>
    </Box>
  );
};

export default TimeRangeValidation;
