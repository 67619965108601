// @ts-nocheck
import { useParams } from "react-router-dom";
import {
  useGetCalendarBySpace,
  useGetCalendarBySpaceWidget,
  useGetClientsPasses,
  useGetLabelsForAdvancedNotice,
  useGetSpacesById,
  useGetStripePublicKey,
  useGetTimes,
} from "./core/_requests";
import { useEffect, useState } from "react";
import {
  FilterFromUntilTimeData,
  formatDateToYYYYMMDD,
  getDayOfWeek,
} from "./core/_utils";
import { Box } from "@mui/material";
import AppBarComponent from "../components/appbar";
import BottomStepper from "../stepper/bottom-stepper";
import DateTimeSelection from "../stepper/date-time-selection-step";
import { format } from "date-fns";
import AddOnsSelection from "../stepper/add-ons-selection";
import InvoiceStep from "../stepper/InvoiceStep";
import SignInSignUpPage from "../stepper/SignInSignUpPage";
import PassesStep from "../stepper/PassesStep";
import { useAuth } from "../hooks/useAuth";
import { selectedRangeProps } from "./core/_models";
import CustomBottomStepper from "../stepper/CustomBottomStepper";
import moment from "moment";

const Room = () => {
  const initialSelectedRange: selectedRangeProps = {
    totalAmount: 0,
    subTotalAmount: 0,
    totalHours: 0,
    totalHoursPrice: 0,
    totalAddonsPrice: 0,
    totalDiscount: 0,
    reservation: [],
  };
  const [dUnitId, setDUnitId] = useState('')
  const auth = useAuth();
  const { productId } = useParams();
  const [day, setDay] = useState<Date | null>(new Date());

  const { data: calendar, isSuccess: calendarSuccess } = useGetCalendarBySpace({
    roomId: productId as string,
    date: formatDateToYYYYMMDD(day),
  });




  const { data: stripePublishKey, isSuccess: stripeIsSuccess } = useGetStripePublicKey()


  const { data: detailsSpace, isSuccess: detailsSuccess } = useGetSpacesById({

    roomId: productId as string,
    withFilter: 'photos'

  })

  const [selectedDays, setSelectedDays] = useState([]);
  const { data: timesData, isSuccess: timesSuccess } = useGetTimes();
  const { data: passesData } = useGetClientsPasses({
    client: auth?.user?.id,
    withFilter: "pass",
    loggedIn: auth.isLoggedIn,
    availableDays: "Monday",
  });

  // Initialize with today's date
  const [selectedHourRate, setSelectedHourRate] = useState({});
  const [availableTimeData, setAvailableTimeData] = useState<any>([]);
  const [availableTimeDataTest, setAvailableTimeDataTest] = useState<any>([]);

  const [selectedRange, setSelectedRange] = useState<selectedRangeProps | {}>(
    {}
  );

  const [currentRange, setCurrentRange] = useState<{
    start: string | null;
    end: string | null;
  }>({
    start: null,
    end: null,
  });
  const [timeRanges, setTimeRanges] = useState<
    { start: string; end: string }[]
  >([]);
  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Booking", "Add-Ons", "Summary", "Checkout",];

  
  useEffect(() => {
    if (calendarSuccess && timesSuccess) {

      const selectedDate = day
      const options: Intl.DateTimeFormatOptions = { weekday: 'long' }
      const currentDay = selectedDate.toLocaleDateString('en-US', options)
      // const  = "Monday";
      const { hour_rates, units } = calendar;
      const result = getNextClosestDayWithDate(hour_rates)


      let selectedHourRate = hour_rates?.filter(
        (el: any) => el.day === currentDay
      );
      if ((formatDateToYYYYMMDD(day) == moment(new Date()).format("YYYY-MM-DD")) && selectedHourRate?.length == 0) {
        setDay(new Date(result?.date))
        let selectedHourRate = hour_rates?.filter(
          (el: any) => el.day === result.day
        );
      }
      const earliestFrom = selectedHourRate && selectedHourRate.length > 0
        ? selectedHourRate?.reduce((earliest, current) => {
          return convertTo24HourFormat(current.from) < convertTo24HourFormat(earliest.from)
            ? current
            : earliest;
        }) : null;
      updateAvailability(calendar?.units, selectedHourRate)
      // Trouver le plus grand "until" (heure de fin)
      const latestUntil = selectedHourRate && selectedHourRate.length > 0
        ? selectedHourRate.reduce((latest, current) => {
          return convertTo24HourFormat(current.until) > convertTo24HourFormat(latest.until)
            ? current
            : latest;
        })
        : null;


      const filteredTimeData = FilterFromUntilTimeData(
        timesData,
        earliestFrom?.from,
        latestUntil?.until
      );

      setSelectedHourRate(selectedHourRate);
      setAvailableTimeData(filteredTimeData);
      setAvailableTimeDataTest(units?.availability)
    }
  }, [calendarSuccess, timesSuccess, day, calendar, timesData]);
  const updateAvailability = (data: any, selectedHourRate: any) => {
    const availability = data?.availability


    const resultFilter = selectedHourRate?.map((item) =>


      getIntervals(availability, item?.from, item?.until, item)

    )

    const result = isSlotBlocked(selectedHourRate[0]?.period, selectedHourRate[0]?.advanced_notice,);



  }
  const filterAvailability = (day, availability, startTime, endTime, date, hourRates) => {

    const start = startTime == "12:30 AM" ? "00:30" : startTime == "12:00 AM" ? "00:00" : startTime;
    const end = endTime;

    const test = availability?.filter(slot => {
      const slotStart = slot.start == "12:30 AM" ? "00:30" : slot.start == "12:00 AM" ? "00:00" : slot.start;
      const slotEnd = slot.end;

      return slotStart >= start && slotEnd <= end;
    });
    const result = isSlotBlocked(date, hourRates.period, hourRates.advanced_notice,);




  };


  function timeTo24HourFormat(time) {
    const [timePart, modifier] = time.split(" ");
    let [hours, minutes] = timePart.split(":").map(Number);

    if (modifier === "PM" && hours !== 12) {
      hours += 12;
    }
    if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    return new Date(`1970-01-01T${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`);
  }
  function getIntervals(array, startTime, endTime, hourRate) {
    const result = [];
    const startTimeObj = timeTo24HourFormat(startTime);
    const endTimeObj = timeTo24HourFormat(endTime);

    for (const item of array) {
      const itemStart = timeTo24HourFormat(item.start);
      const itemEnd = timeTo24HourFormat(item.end);

      if (itemStart >= startTimeObj && itemEnd <= endTimeObj) {
        result.push(item);
      }
    }
    const resultBlocked = isSlotBlocked(hourRate.period, hourRate.advanced_notice,);
    if (!resultBlocked) {
      result?.forEach((slot, index) => {
        slot.available = false;
        if (index == 0) {
          slot.advancedNotice = true;
        }
      
      });
    }

  }
  const isSlotBlocked = (advancedNoticeUnit, advancedNotice,) => {
    const currentDate = moment().format("YYYY-MM-DD")

    let advancedNoticeDate;
    switch (advancedNoticeUnit) {
      case 'days':
        advancedNoticeDate = moment(currentDate).add(Number(advancedNotice), 'days');
        break;
      case 'weeks':
        advancedNoticeDate = moment(currentDate).add(Number(advancedNotice), 'weeks');
        break;
      case 'months':
        advancedNoticeDate = moment(currentDate).add(Number(advancedNotice), 'months');
        break;
      default:
        advancedNoticeDate = moment(currentDate);
        break;
    }

    if (moment(day).isAfter(advancedNoticeDate)) {
      return true; // Slot is blocked
    } else {
      return false;
    }

  };
  const getNextClosestDayWithDate = (days) => {
    const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];


    const today = new Date();
    const currentDayIndex = today.getDay();


    const availableDayIndexes = days.map((day) => daysOfWeek.indexOf(day.day));

    const nextDayIndex = availableDayIndexes
      .sort((a, b) => a - b)
      .find((dayIndex) => dayIndex > currentDayIndex);

    const closestDayIndex = nextDayIndex !== undefined ? nextDayIndex : Math.min(...availableDayIndexes);

    let daysUntilNext = closestDayIndex - currentDayIndex;
    if (daysUntilNext < 0) {
      daysUntilNext += 7;
    }

    const nextDate = new Date(today);
    nextDate.setDate(today.getDate() + daysUntilNext);


    const formattedDate = nextDate.toLocaleDateString("en-US");


    return {
      day: daysOfWeek[closestDayIndex],
      date: formattedDate,
    };
  };
  const convertTo24HourFormat = (time) => {
    const [timePart, modifier] = time.split(' '); // Séparer l'heure de AM/PM
    let [hours, minutes] = timePart.split(':').map(Number);

    if (modifier === 'PM' && hours < 12) {
      hours += 12;
    }
    if (modifier === 'AM' && hours === 12) {
      hours = 0;
    }

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };
  const handleNext = () => {
    setActiveStep((prevStep) => {
      // Check if we need to move to the next step directly
      if (prevStep === 0 && calendar?.add_ons.length === 0) {
        // Return the new step number directly
        return prevStep + 2;
      }
      // Otherwise, return the incremented step number
      return prevStep + 1;
    });
  };

  const handleBack = () => {
    setActiveStep((prevStep) => {
      // Check if we need to move to the next step directly
      if (prevStep === 2 && calendar?.add_ons.length === 0) {
        // Return the new step number directly
        return prevStep - 2;
      }
      // Otherwise, return the incremented step number
      return prevStep - 1;
    });
    // setActiveStep(activeStep - 1);
  };

  const handleChangeDiscount = (selectedPass) => {
    if (selectedPass === null) handleRemovePass();
    for (let selected of (selectedRange as selectedRangeProps).reservation) {
      const accessItem = selectedPass?.pass?.accesses?.find(
        (item) => item?.day === getDayOfWeek(selected.day)
      );
      if (accessItem) {
        setDiscount(`${accessItem.units}${accessItem.sub_type}`);
      }
    }
  };

  const applyDiscount = (amount, discountValue, remainingDiscountRef) => {
    if (typeof discountValue === "string" && discountValue.includes("%")) {
      // Percentage discount
      const percentage = parseFloat(discountValue) / 100;
      const discountAmount = Math.min(
        Number(amount) * percentage,
        remainingDiscountRef.current
      );
      remainingDiscountRef.current -= discountAmount;
      return Math.max(Number(amount) - discountAmount, 0);
    } else if (
      typeof discountValue === "string" &&
      discountValue.includes("$")
    ) {
      // Fixed amount discount
      const fixedDiscount = Math.min(
        parseFloat(discountValue),
        remainingDiscountRef.current,
        amount
      );
      remainingDiscountRef.current -= fixedDiscount;
      return Math.max(Number(amount) - fixedDiscount, 0);
    } else if (
      typeof discountValue === "string" &&
      discountValue.includes("uses") &&
      remainingDiscountRef.current !== 0
    ) {
      // Discount based on uses
      const discountAmount = Number(amount);
      remainingDiscountRef.current -= discountAmount;
      return Math.max(Number(amount) - discountAmount, 0); // Ensure no negative values
    } else if (
      typeof discountValue === "string" &&
      discountValue.includes("hours")
    ) {
      // Implement hours discount logic
      // Ensure to return a non-negative amount here as well
    }
    return amount; // Default case if no discount is applied
  };

  const setDiscount = (discountValue) => {
    const initialRemainingDiscount = parseFloat(discountValue);

    setSelectedRange((prevSelectedRange: selectedRangeProps | {}) => {
      if ("reservation" in prevSelectedRange) {
        const updatedReservation = prevSelectedRange.reservation.map(
          (reservation) => {
            let remainingDiscountRef = { current: initialRemainingDiscount }; // Reset remaining discount for each reservation

            const updatedRanges = reservation.range.map((range) => {
              const discountedAmount = applyDiscount(
                range.subTotalAmount,
                discountValue,
                remainingDiscountRef
              );

              // Ensure totalDiscount and totalAmount are non-negative
              const totalDiscount = Math.max(
                range.subTotalAmount - discountedAmount,
                0
              );
              const totalAmount = discountedAmount;

              return {
                ...range,
                totalDiscount,
                totalAmount,
              };
            });

            const totalAddonsPriceForReservation = updatedRanges.reduce(
              (sum, range) => sum + range.totalAddonsPrice,
              0
            );

            const totalDiscountForReservation = updatedRanges.reduce(
              (sum, range) => sum + range.totalDiscount,
              0
            );

            const totalAmountForReservation = updatedRanges.reduce(
              (sum, range) => sum + range.totalAmount,
              0
            );

            return {
              ...reservation,
              range: updatedRanges,
              totalAddonsPrice: totalAddonsPriceForReservation,
              totalDiscount: totalDiscountForReservation,
              totalAmount: totalAmountForReservation,
              subTotalAmount: totalAmountForReservation,
            };
          }
        );

        const newTotalDiscount = updatedReservation.reduce(
          (sum, res) => sum + res.totalDiscount,
          0
        );
        const newSubTotalAmount = updatedReservation.reduce(
          (sum, res) => sum + res.subTotalAmount,
          0
        );

        return {
          ...prevSelectedRange,
          totalDiscount: newTotalDiscount,
          subTotalAmount: newSubTotalAmount,
          totalAmount: Math.max(newSubTotalAmount - newTotalDiscount, 0),
          reservation: updatedReservation,
        };
      }
      return prevSelectedRange;
    });
  };

  const handleRemovePass = () => {
    setSelectedRange((prevSelectedRange: selectedRangeProps | {}) => {
      if ("reservation" in prevSelectedRange) {
        const updatedReservation = prevSelectedRange.reservation.map(
          (reservation) => {
            const updatedRanges = reservation.range.map((range) => {
              return {
                ...range,
                totalAmount: range.subTotalAmount, // Reset totalAmount to subTotalAmount
                totalDiscount: 0, // Reset totalDiscount to 0
              };
            });

            const totalAddonsPriceForReservation = updatedRanges.reduce(
              (sum, range) => sum + range.totalAddonsPrice,
              0
            );

            const totalDiscountForReservation = updatedRanges.reduce(
              (sum, range) => sum + range.totalDiscount,
              0
            );

            const totalAmountForReservation = updatedRanges.reduce(
              (sum, range) => sum + range.totalAmount,
              0
            );

            return {
              ...reservation,
              range: updatedRanges,
              totalAddonsPrice: totalAddonsPriceForReservation,
              totalDiscount: totalDiscountForReservation,
              totalAmount: totalAmountForReservation,
              subTotalAmount: totalAmountForReservation,
            };
          }
        );

        const newTotalDiscount = updatedReservation.reduce(
          (sum, res) => sum + res.totalDiscount,
          0
        );
        const newSubTotalAmount = updatedReservation.reduce(
          (sum, res) => sum + res.subTotalAmount,
          0
        );

        return {
          ...prevSelectedRange,
          totalDiscount: newTotalDiscount,
          subTotalAmount: newSubTotalAmount,
          totalAmount: Math.max(newSubTotalAmount - newTotalDiscount, 0),
          reservation: updatedReservation,
        };
      }
      return prevSelectedRange;
    });
  };

  const onResetDay = (day) => {
    const formattedDay = format(new Date(day), "yyyy-MM-dd");

    // Filter out the reservation with the matching day
    setSelectedRange((prev) => {
      if ("reservation" in prev) {
        const updatedReservation = prev.reservation.filter(
          (res) => res.day !== formattedDay
        );

        // Recalculate the total values after removing the reservation
        const newTotalHours = updatedReservation.reduce(
          (sum, res) => sum + res.totalHours,
          0
        );
        const newTotalHoursPrice = updatedReservation.reduce(
          (sum, res) => sum + res.totalHoursPrice,
          0
        );
        const newTotalAddonsPrice = updatedReservation.reduce(
          (sum, res) => sum + res.totalAddonsPrice,
          0
        );
        const newTotalDiscount = updatedReservation.reduce(
          (sum, res) => sum + res.totalDiscount,
          0
        );
        const newSubTotalAmount = updatedReservation.reduce(
          (sum, res) => sum + res.subTotalAmount,
          0
        );

        return {
          ...prev,
          reservation: updatedReservation,
          totalHours: newTotalHours,
          totalHoursPrice: newTotalHoursPrice,
          totalAddonsPrice: newTotalAddonsPrice,
          totalDiscount: newTotalDiscount,
          subTotalAmount: newSubTotalAmount,
          totalAmount: newSubTotalAmount - newTotalDiscount,
        };
      }
      return prev;
    });

    // Clear the last value in timeRanges
    setTimeRanges((prev) => prev.slice(0, -1));
    setCurrentRange({
      start: null,
      end: null,
    });
  };

  const resetSelectedRange = () => {
    setTimeRanges((prev) => prev.slice(0, -1));

    setCurrentRange({
      start: null,
      end: null,
    });

    setSelectedRange(initialSelectedRange);
    setActiveStep(0);
  };

  useEffect(() => {
    if (
      selectedRange &&
      "reservation" in selectedRange &&
      selectedRange.reservation.length === 0
    ) {
      setActiveStep(0);
      setTimeRanges([]);
    }
  }, [selectedRange]); // Dependency array includes selectedRange to run the effect when it changes

  const deleteSelectedRange = (
    reservationIndex: number,
    rangeIndex: number
  ) => {
    setSelectedRange((prevSelectedRange: selectedRangeProps | {}) => {
      if ("reservation" in prevSelectedRange) {
        const updatedReservation = prevSelectedRange.reservation.map(
          (reservation, resIndex) => {
            if (resIndex === reservationIndex) {
              const updatedRange = reservation.range.filter(
                (_, rIndex) => rIndex !== rangeIndex
              );

              const totalHours = updatedRange.reduce(
                (sum, range) => sum + range.totalHours,
                0
              );
              const totalHoursPrice = updatedRange.reduce(
                (sum, range) => sum + range.totalHoursPrice,
                0
              );
              const totalAddonsPrice = updatedRange.reduce(
                (sum, range) => sum + range.totalAddonsPrice,
                0
              );
              const subTotalAmount = totalHoursPrice + totalAddonsPrice;
              const totalDiscount = reservation.totalDiscount;
              const totalAmount = subTotalAmount - totalDiscount;

              return {
                ...reservation,
                range: updatedRange,
                totalHours,
                totalHoursPrice,
                totalAddonsPrice,
                subTotalAmount,
                totalDiscount,
                totalAmount,
              };
            }
            return reservation;
          }
        );

        const filteredReservations = updatedReservation.filter(
          (reservation) => reservation.range.length > 0
        );

        const totalHours = filteredReservations.reduce(
          (sum, res) => sum + res.totalHours,
          0
        );
        const totalHoursPrice = filteredReservations.reduce(
          (sum, res) => sum + res.totalHoursPrice,
          0
        );
        const totalAddonsPrice = filteredReservations.reduce(
          (sum, res) => sum + res.totalAddonsPrice,
          0
        );
        const subTotalAmount = filteredReservations.reduce(
          (sum, res) => sum + res.subTotalAmount,
          0
        );
        const totalDiscount = filteredReservations.reduce(
          (sum, res) => sum + res.totalDiscount,
          0
        );
        const totalAmount = subTotalAmount - totalDiscount;

        return {
          ...prevSelectedRange,
          totalHours,
          totalHoursPrice,
          totalAddonsPrice,
          subTotalAmount,
          totalDiscount,
          totalAmount,
          reservation: filteredReservations,
        };
      }
      return prevSelectedRange;
    });
  };
  const { data: calendarWidget, isSuccess: calendarWidgetSuccess } = useGetCalendarBySpaceWidget({
    roomId: productId as string,
    date: formatDateToYYYYMMDD(day),
    time: currentRange?.start
  });


  const handleAddComment = (e, reservationIndex) => {
    const newComment = e.target.value;

    setSelectedRange((prevSelectedRange: selectedRangeProps | {}) => {
      if ("reservation" in prevSelectedRange) {
        const updatedReservations = prevSelectedRange.reservation.map(
          (reservation, index) => {
            if (index === reservationIndex) {
              // Update the comment for the selected reservation
              return {
                ...reservation,
                special_request: newComment,
              };
            }
            return reservation;
          }
        );

        // Log the updated comment

        return {
          ...prevSelectedRange,
          reservation: updatedReservations,
        };
      }
      return prevSelectedRange;
    });
  };


  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        backgroundColor: "#e2e2e2",
      }}
    >
      <AppBarComponent
        roomName={calendar?.entitled}
        steps={steps}
        activeStep={activeStep}
        second_widget_color={detailsSpace?.second_widget_color}
        main_widget_color={detailsSpace?.main_widget_color}
        css_widget={detailsSpace?.css_widget}

      />
      <Box sx={{ flexGrow: 1, overflow: "auto", p: 2, mb: 5 }}>
        {calendarSuccess && (
          <>
            {activeStep === 0 && (
              <DateTimeSelection
                css_widget={detailsSpace?.css_widget}
                detailsSpace={detailsSpace}
                selectedUnits={calendar?.units}
                day={day}
                setDay={setDay}
                hourRates={calendar?.hour_rates}
                availableTimeData={availableTimeData}
                timeRanges={timeRanges}
                setTimeRanges={setTimeRanges}
                selectedRange={selectedRange}
                setSelectedRange={setSelectedRange}
                onResetDay={onResetDay}
                currentRange={currentRange}
                setCurrentRange={setCurrentRange}
                resetSelectedRange={resetSelectedRange}
                selectedHourRate={selectedHourRate}
                second_widget_color={detailsSpace?.second_widget_color}
                main_widget_color={detailsSpace?.main_widget_color}
                calendarSuccess={calendarSuccess}
                setDUnitId={setDUnitId}
                photos={detailsSpace?.photos?.find(item => item?.type == 1)?.url
                  ? detailsSpace?.photos?.find(item => item?.type == 1)?.url
                  : ""}
                productId={productId}
                convertTo24HourFormat={convertTo24HourFormat}

              />
            )}
            {activeStep === 1 && (
              <AddOnsSelection

                setSelectedRange={setSelectedRange}
                selectedRange={selectedRange}
                addOnsList={calendar?.add_ons}
                resetSelectedRange={resetSelectedRange}
                second_widget_color={detailsSpace?.second_widget_color}
                main_widget_color={detailsSpace?.main_widget_color}
                css_widget={detailsSpace?.css_widget}
              />
            )}
            {activeStep === 2 && (
              <InvoiceStep
                selectedRange={selectedRange}
                handleChangeDiscount={handleChangeDiscount}
                deleteSelectedRange={deleteSelectedRange}
                handleAddComment={handleAddComment}
                resetSelectedRange={resetSelectedRange}
                second_widget_color={detailsSpace?.second_widget_color}
                main_widget_color={detailsSpace?.main_widget_color}
                css_widget={detailsSpace?.css_widget}
              />
            )}
            {activeStep === 3 && (
              <SignInSignUpPage
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                second_widget_color={detailsSpace?.second_widget_color}
                main_widget_color={detailsSpace?.main_widget_color}
                css_widget={detailsSpace?.css_widget}
                productId={productId}
                spaceId={detailsSpace?.id}
              />
            )}
            {activeStep === 4 && (
              <PassesStep
                clientSecret={
                  localStorage.getItem('client_secret')
                }
                selectedUnits={dUnitId}
                productId={detailsSpace?.id}
                addOnsList={calendar?.add_ons}
                passesData={passesData}
                selectedRange={selectedRange}
                setSelectedRange={setSelectedRange}
                second_widget_color={detailsSpace?.second_widget_color}
                main_widget_color={detailsSpace?.main_widget_color}
                publicKey={stripePublishKey?.public_key}
                css_widget={detailsSpace?.css_widget}
              />
            )}
          </>
        )}
      </Box>
      {/* <BottomStepper
        selectedRange={selectedRange}
        steps={steps}
        activeStep={activeStep}
        handleNext={handleNext}
        handleBack={handleBack}
      /> */}
      <CustomBottomStepper
        selectedRange={selectedRange}
        steps={steps}
        activeStep={activeStep}
        handleNext={handleNext}
        handleBack={handleBack}
        second_widget_color={detailsSpace?.second_widget_color}
        main_widget_color={detailsSpace?.main_widget_color}
        css_widget={detailsSpace?.css_widget}
      />
    </Box>
  );
};
export default Room;
