import { Box, IconButton, Typography } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close";
import { extractStyles, hourUnitFromatter } from "../Pages/core/_utils";
import { useEffect, useState } from "react";

const TotalTimeSelectedCard =({totalHoursSelected,HourUnit,resetSelectedRange,css_widget})=>{

  const [stylesOne, setStylesOne] = useState({});
  const [stylesTwo, setStylesTwo] = useState({});

  useEffect(() => {
      if (css_widget && typeof css_widget === 'string') {
          const extractedStyles = extractStyles(css_widget, '.card-wrap');
          const extractedStylesTwo = extractStyles(css_widget, ' .total-time-card');
          setStylesOne(extractedStyles);
          setStylesTwo(extractedStylesTwo)
      } else {
          
      }
  }, [css_widget]);
    return(
        <Box sx={{ display: "flex", alignItems: "center" , ...stylesOne,stylesTwo }} p={1} >
        <Typography variant="h6" fontWeight={600} sx={{ marginRight: 1 }}>
          {totalHoursSelected}{" "}
          {hourUnitFromatter(totalHoursSelected) }
        </Typography>
        <IconButton onClick={resetSelectedRange}>
          <CloseIcon sx={{ cursor: "pointer" }} />
        </IconButton>
      </Box>
    )
}

export default TotalTimeSelectedCard