import { useState } from "react";
import { Box, Table, TableRow, Typography, styled } from "@mui/material";
import { convertTo24HourFormat } from "../Pages/core/_utils";
import { BorderBottom, BorderLeft, BorderRight } from "@mui/icons-material";
import { useGetLabelsForAdvancedNotice } from "../Pages/core/_requests";

interface SlotCellProps {
  selected?: boolean;
  disabled?: boolean;
  main_widget_color: string;
  hoverEffect: boolean;
  hoveredIndex: any
  index: any
  borderRight: any
  borderTop: any
  borderBottom: any
}

const TimeCell = styled("td")(() => ({
  padding: 5,
  textAlign: "center",
  width: "80px",
  backgroundColor: "#fff",
  border: "none",
}));

const SlotCell = styled("td")<SlotCellProps>(({ selected, disabled, main_widget_color, hoverEffect, hoveredIndex, borderRight, borderTop, borderBottom }) => ({
  padding: "7px",
  textAlign: "center",
  width: "80px",
  backgroundColor: selected ? (main_widget_color !== null ? `${main_widget_color} !important ` : "#e0e0e0") : disabled ? "#9e9e9e" : "#fff",
  cursor: disabled ? "not-allowed" : "pointer",
  fontWeight: "700",
  // color: disabled ? "#9e9e9e" : "black",
  color: "white",
  // transition: "border 0.3s ease", // Smooth transition

  borderLeft: disabled ? "" : borderRight ? `1px solid ${main_widget_color} !important` : "none",
  borderRight: disabled ? "" : borderRight ? `1px solid ${main_widget_color} !important` : "none",
  borderTop: disabled ? "" : borderTop ? `1px solid ${main_widget_color} !important` : disabled ? "" : !selected ? "1px solid #e2e2e2" : '',
  borderBottom:  disabled ? "":borderBottom ? `1px solid ${main_widget_color} !important` : "",
  // "&:hover": {
  //   BorderBottom: hoverEffect ? `1px solid ${main_widget_color}` : "none",
  // },
}));

const TimePicker = ({
  availableTimeData,
  currentRange,
  setCurrentRange,
  timeRanges,
  isReserved,
  selectedHourRate,
  availability,
  main_widget_color,
  css_widget,
  convertTo24HourFormat
}) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const { data: dataLabel } = useGetLabelsForAdvancedNotice()
  // const handleTimeClick = (time) => {

  //   if (!isDisabled(time)) {
  //     setCurrentRange({ start: time, end: null });
  //     const startTimeIndex = availableTimeData.findIndex((t) => t.time === time);
  //     if (startTimeIndex === -1) return;

  //     const stepsToGo = selectedHourRate.rate_per / 0.5;
  //     const endTimeIndex = startTimeIndex + stepsToGo;
  //     console.log("isDisabled(time)", selectedHourRate)

  //     if (endTimeIndex < availableTimeData.length) {
  //       const endTime = availableTimeData[endTimeIndex].time;
  //       const convertedEndTime = convertTo24HourFormat(endTime);
  //       const isTimeUnavailable = timeRanges?.some((range) => convertedEndTime >= convertTo24HourFormat(range.start));
  //       const isEndTimeAvailable = availability?.some((slot) => slot.start === endTime && slot.available);

  //       if (!isTimeUnavailable || isEndTimeAvailable) {
  //         setCurrentRange((prevRange) => ({
  //           ...prevRange,
  //           end: endTime
  //         }));
  //       }
  //     }
  //   }
  // };
  const handleTimeClick = (time, row) => {

    if (!isDisabledHourRates(time, row)) {

      setCurrentRange({ start: time, end: null });
      const startTimeIndex = availableTimeData.findIndex((t) => t.time === time);
      if (startTimeIndex === -1) return;
      const result = findObjectByTime(selectedHourRate, time)
      const stepsToGo = result?.rate_per / 0.5;
      const endTimeIndex = startTimeIndex + stepsToGo;


      if (endTimeIndex < availableTimeData.length) {
        const endTime = availableTimeData[endTimeIndex].time;
        const convertedEndTime = convertTo24HourFormat(endTime);
        const isTimeUnavailable = timeRanges?.some((range) => convertedEndTime >= convertTo24HourFormat(range.start));
        const isEndTimeAvailable = availability?.some((slot) => slot.start === endTime && slot.available);

        if (!isTimeUnavailable || isEndTimeAvailable) {
          setCurrentRange((prevRange) => ({
            ...prevRange,
            end: endTime
          }));
        }
      }
    }
  };

  const isTimeInRange = (time, from, until) => {
    const time24 = convertTo24HourFormat(time);
    const from24 = convertTo24HourFormat(from);
    const until24 = convertTo24HourFormat(until);

    return time24 >= from24 && time24 <= until24;
  };

  const findObjectByTime = (filteredData, time) => {


    // Rechercher l'objet dont l'heure donnée est comprise entre from et until
    const foundObject = filteredData.find(item =>
      isTimeInRange(time, item.from, item.until)
    );

    return foundObject || null; // Retourner l'objet trouvé ou null s'il n'y a pas de correspondance
  };


  const isSelected = (time: string, row) => {

    if (!currentRange.start) return false;
    const startIndex = availableTimeData.findIndex((t: any) => t.time === currentRange.start);
    const endIndex = currentRange.end
      ? availableTimeData.findIndex((t: any) => t.time === currentRange.end)
      : startIndex;
    const currentIndex = availableTimeData.findIndex((t: any) => t.time === time);

    return currentIndex >= startIndex && currentIndex <= endIndex;
  };


  const isDisabled = (time: string, row) => {

    return timeRanges?.some((range) => {
      const startIndex = availableTimeData.findIndex((t: any) => t.time === range.start);
      const endIndex = availableTimeData.findIndex((t: any) => t.time === range.end);
      const currentIndex = availableTimeData.findIndex((t: any) => t.time === time);

      return currentIndex >= startIndex && currentIndex <= endIndex;
    });
  };

  const isDisabledHourRates = (time: string, row) => {

    return availability && availability?.some((range) => {

      if (!range.available) {
        const startIndex = availableTimeData.findIndex(
          (t: any) => t.time === range.start
        );
        const endIndex = availableTimeData.findIndex(
          (t: any) => t.time === range.end
        );
        const currentIndex = availableTimeData.findIndex(
          (t: any) => t.time === time
        );

        return currentIndex >= startIndex && currentIndex <= endIndex;
      }
      return false;
    });
  };

  const handleMouseEnter = (index: number) => {
    setHoveredIndex(index);
  };

  const handleMouseLeave = () => {
    setHoveredIndex(null);
  };


  const showTitleAdvancedNotice = (time: string, row) => {

    return availability && availability?.some((range,) => {

      if (range.advancedNotice) {
        const startIndex = availableTimeData.findIndex(
          (t: any) => t.time === range.start
        );
        const endIndex = availableTimeData.findIndex(
          (t: any) => t.time === range.end
        );
        const currentIndex = availableTimeData.findIndex(
          (t: any) => t.time === time
        );

        return currentIndex == startIndex;
      }
      return false;
    });
  };

  return (
    <Table>
      <tbody>
        {availableTimeData.map((time: any, index: number) => (
          <TableRow key={index}>
            <TimeCell>
              <Typography variant="body2">{time.time}</Typography>
            </TimeCell>
            <SlotCell
              colSpan={5}
              onClick={() => handleTimeClick(time.time, time)}
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={handleMouseLeave}
              selected={isSelected(time.time, time) || isDisabled(time.time, time) || isReserved(time.time, time)}
              disabled={isDisabled(time.time, time) || isReserved(time.time, time) || isDisabledHourRates(time?.time, time)}
              main_widget_color={main_widget_color}
              hoverEffect={hoveredIndex === index || hoveredIndex === index - 1 || hoveredIndex === index - 2}
              hoveredIndex={hoveredIndex}
              index={index}
              borderRight={hoveredIndex === (index - 1) || hoveredIndex === index || hoveredIndex === (index - 2) ? true : false}
              borderTop={hoveredIndex === (index) ? true : false}
              borderBottom={hoveredIndex === (index - 2) ? true : false}

            > {showTitleAdvancedNotice(time?.time, time) ? dataLabel?.value : ""}</SlotCell>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
};

export default TimePicker;
