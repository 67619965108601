import { Box, Button, Card, CardContent, styled, Typography } from '@mui/material';
import React from 'react'


const BookingSucces = ({ main_widget_color,productId }) => {


    function handleClick() {
        window.location.replace(`/room/${productId}`);
    }
    const StyledLoginCard = styled(Card)(({ theme }) => ({
        height: "70%",
        boxShadow: "0px 4px 8px 8px rgba(183, 184, 184, 0.25)",
    }));
    return (
        <Box sx={{ width: "100%", maxWidth: 600, mx: "auto" }}>
            <StyledLoginCard>
                <CardContent
                    sx={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        py: 5
                    }}
                >
                    <Typography variant='h3' >Thank You!</Typography>
                    <Typography variant='h6' mt={5} >A Confirmation email will be sent to </Typography>
                    <Typography variant='h6' mt={1}> You shortly</Typography>
                    <Typography variant='h6' mt={1} >We'll see you soon!</Typography>

                    <Box
                        sx={{ width: "100%", maxWidth: 400, mx: "auto", paddingTop: 2 }}>
                        <Button variant="contained" sx={{
                            backgroundColor: main_widget_color !== null ? `${main_widget_color} !important ` : 'var(--primary-color)',
                            '&:hover': {
                                backgroundColor: main_widget_color !== null ? `${main_widget_color} !important ` : 'var(--primary-color)',
                            },

                        }}  onClick={handleClick}  fullWidth>
                            Book again
                        </Button>
                    </Box>
                </CardContent>
            </StyledLoginCard></Box>
    )
}

export default BookingSucces