import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { extractStyles, formatDateString, getDayOfWeek } from "../Pages/core/_utils";
import { ClearIcon } from "@mui/x-date-pickers";
import { selectedRangeProps } from "../Pages/core/_models";
import { useEffect, useState } from "react";
import PaymentForm from "../components/PaymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useGetExtraData } from "../Pages/core/_requests";
import BookingSucces from "./BookingSucces";


const PassesStep = ({
  passesData,
  selectedRange,
  setSelectedRange,
  clientSecret,
  addOnsList,
  productId,
  selectedUnits,
  main_widget_color,
  publicKey,
  css_widget
}) => {
  const stripePromise = loadStripe(
    publicKey);
  const [passId, setPassId] = useState(null)
  const { data: extraData } = useGetExtraData()

  const handleChangeDiscount = (selectedPass) => {

    if (selectedPass === null) {
      handleRemovePass();
      setPassId(null)
    } else {
      setPassId(selectedPass?.d_pass_id)
    }

    for (let selected of (selectedRange as selectedRangeProps).reservation) {
      const accessItem = selectedPass?.pass?.accesses?.find(
        (item) => item?.day === getDayOfWeek(selected.day)
      );

      if (accessItem) {
        setDiscount(`${accessItem.units} ${accessItem.sub_type}`);
      }
    }
  };

  const applyDiscount = (amount, discountValue, remainingDiscountRef, range,
    maxTotalHoursObject) => {
    if (typeof discountValue === "string" && discountValue.includes("%")) {
      // Percentage discount
      const percentage = parseFloat(discountValue) / 100;
      const discountAmount = Math.min(
        Number(amount) * percentage,
        remainingDiscountRef.current
      );
      remainingDiscountRef.current -= discountAmount;
      return Math.max(Number(amount) - discountAmount, 0);
    } else if (
      typeof discountValue === "string" &&
      discountValue.includes("$")
    ) {
      // Fixed amount discount
      const fixedDiscount = Math.min(
        parseFloat(discountValue),
        remainingDiscountRef.current,
        amount
      );
      remainingDiscountRef.current -= fixedDiscount;
      return Math.max(Number(amount) - fixedDiscount, 0);
    } else if (
      typeof discountValue === "string" &&
      discountValue.includes("uses") &&
      remainingDiscountRef.current !== 0
    ) {
      // Discount based on uses
      const discountAmount = Number(amount);
      remainingDiscountRef.current -= discountAmount;
      return Math.max(Number(amount) - discountAmount, 0); // Ensure no negative values
    } else if (
      typeof discountValue === "string" &&
      discountValue.includes("hours")
    ) {

      const unitHoursPrice = Number(maxTotalHoursObject.totalHoursPrice / maxTotalHoursObject.totalHours)
      let discountHours = 0

      if (range.totalHours < remainingDiscountRef.current) {


        discountHours = range.totalAmount
      } else {
        let totalhoursDiscount = Number(maxTotalHoursObject.totalHours) - Number(remainingDiscountRef.current)

        discountHours = (Number(maxTotalHoursObject.totalHours) - totalhoursDiscount) * unitHoursPrice
      }

      return discountHours

    }
    return amount;
  };
  const setDiscount = (discountValue) => {
    const initialRemainingDiscount = parseFloat(discountValue);

    setSelectedRange((prevSelectedRange: selectedRangeProps | {}) => {

      if ("reservation" in prevSelectedRange) {
        let remainingDiscountRef = { current: initialRemainingDiscount };

        const updatedReservationV1 = prevSelectedRange.reservation;

        // Trouver l'objet avec le maximum de `totalHours`
        const maxTotalHoursObject = updatedReservationV1.reduce((maxObj, currentObj) => {
          return currentObj.totalHours > maxObj.totalHours ? currentObj : maxObj;
        }, updatedReservationV1[0]);

        // Mettre à jour les réservations en ajoutant `maxHour: true` au maxTotalHoursObject
        const updatedReservation = prevSelectedRange.reservation.map((reservation) => {

          const updatedRanges = reservation.range.map((range) => {

            const discountedAmount = applyDiscount(
              range.subTotalAmount,
              discountValue,
              remainingDiscountRef,
              range,
              maxTotalHoursObject
            );

            let totalDiscount = Math.max(
              range.subTotalAmount - discountedAmount,
              0
            );
            let totalAmount = range.subTotalAmount;
            if (typeof discountValue === "string" &&
              discountValue.includes("hours")) {

              // @ts-ignore

              if (reservation.maxHour) {
                totalDiscount = discountedAmount;
                totalAmount = range.subTotalAmount - discountedAmount;
              }

            } else {
              totalDiscount = Math.max(
                range.subTotalAmount - discountedAmount,
                0
              );
              totalAmount = discountedAmount;
            }

            return {
              ...range,
              totalDiscount,
              totalAmount,
            };
          });

          const totalAddonsPriceForReservation = updatedRanges.reduce(
            (sum, range) => sum + range.totalAddonsPrice,
            0
          );

          const totalDiscountForReservation = updatedRanges.reduce(
            (sum, range) => sum + range.totalDiscount,
            0
          );
          const totalAmountReservation = updatedRanges.reduce(
            (sum, range) => sum + range.totalAmount,
            0
          );

          // Ajouter `maxHour: true` à l'objet avec le max de totalHours
          return {
            ...reservation,
            range: updatedRanges,
            totalAddonsPrice: totalAddonsPriceForReservation,
            totalDiscount: totalDiscountForReservation,
            totalAmount: totalAmountReservation,
            subTotalAmount: totalAmountReservation,
            maxHour: reservation === maxTotalHoursObject ? true : false, // Ajout de maxHour
          };
        });

        const newTotalDiscount = updatedReservation.reduce(
          (sum, res) => sum + res.totalDiscount,
          0
        );
        const newSubTotalAmount = updatedReservation.reduce(
          (sum, res) => sum + res.subTotalAmount,
          0
        );

        return {
          ...prevSelectedRange,
          totalDiscount: newTotalDiscount,
          subTotalAmount: newSubTotalAmount,
          totalAmount: newTotalDiscount,
          reservation: updatedReservation,
        };
      }
      return prevSelectedRange;
    });
  };


  const handleRemovePass = () => {
    setSelectedRange((prevSelectedRange: selectedRangeProps | {}) => {
      if ("reservation" in prevSelectedRange) {
        const updatedReservation = prevSelectedRange.reservation.map(
          (reservation) => {
            const updatedRanges = reservation.range.map((range) => {
              return {
                ...range,
                totalAmount: range.subTotalAmount, // Reset totalAmount to subTotalAmount
                totalDiscount: 0, // Reset totalDiscount to 0
              };
            });

            // Calculate the sum for each reservation
            const totalAddonsPriceForReservation = updatedRanges.reduce(
              (sum, range) => sum + range.totalAddonsPrice,
              0
            );

            const totalDiscountForReservation = updatedRanges.reduce(
              (sum, range) => sum + range.totalDiscount,
              0
            );

            const totalAmountForReservation = updatedRanges.reduce(
              (sum, range) => sum + range.totalAmount,
              0
            );

            return {
              ...reservation,
              range: updatedRanges,
              totalAddonsPrice: totalAddonsPriceForReservation,
              totalDiscount: totalDiscountForReservation,
              totalAmount: totalAmountForReservation,
              subTotalAmount: totalAmountForReservation,
            };
          }
        );

        // Aggregate values for the big parent object
        const newTotalDiscount = updatedReservation.reduce(
          (sum, res) => sum + res.totalDiscount,
          0
        );
        const newSubTotalAmount = updatedReservation.reduce(
          (sum, res) => sum + res.subTotalAmount,
          0
        );

        return {
          ...prevSelectedRange,
          totalDiscount: newTotalDiscount,
          subTotalAmount: newSubTotalAmount,
          totalAmount: Math.max(newSubTotalAmount - newTotalDiscount, 0),
          reservation: updatedReservation,
        };
      }
      return prevSelectedRange;
    });
  };

  const [checkoutPageFormInput, setCheckoutPageFormInput] = useState({
    card_number: null,
    expiration: null,
    cvc: null,
  });

  const handleChangeInput = (formName, event) => {
    setCheckoutPageFormInput((prevValues) => ({
      ...prevValues,
      [formName]: event.target.value,
    }));
  };
  const options = { clientSecret };
  // ************************
  // *********************
  const [stylesOne, setStylesOne] = useState({});
  const [stylesTwo, setStylesTwo] = useState({});
  const [stylesThree, setStylesThree] = useState({});
  const [isBookingSuccess, setIsBookingSuccess] = useState(false);
  useEffect(() => {
    if (css_widget && typeof css_widget === 'string') {
      const extractedStyles = extractStyles(css_widget, '.card-wrap');
      const extractedStylesTwo = extractStyles(css_widget, '.bill-disclaimer');
      const extractedStylesThree = extractStyles(css_widget, '.bill-label');

      setStylesOne(extractedStyles);
      setStylesTwo(extractedStylesTwo)
      setStylesThree(extractedStylesThree)

    } else {
    
    }
  }, [css_widget]);
  return (
    <>
      {isBookingSuccess ?

        <BookingSucces   main_widget_color={main_widget_color}   productId={productId} />
        : <Grid container spacing={2}>
          {passesData?.length > 0 && (
            <>
              <Grid item xs={12}>
                <Box sx={{ backgroundColor: "white", padding: 2 }}>
                  {selectedRange.reservation.map((element, index) => (
                    <Box key={index} p={2}>
                      <Grid container justifyContent="center">
                        <Grid item xs={12}>
                          <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            textAlign="center"
                          >
                            <Typography variant="h6" gutterBottom>
                              {formatDateString(element.day)}
                            </Typography>
                            {element.range.map((el, idx) => (
                              <Box
                                key={idx}
                                p={1}
                                mb={2}
                                width="100%"
                                display="flex"
                                flexDirection="column"
                              >
                                <Box
                                  display="flex"
                                  justifyContent="space-between"
                                  width="100%"
                                >
                                  <Typography
                                    sx={{ mb: 0, fontWeight: 400 }}
                                    color="black"
                                  >
                                    {`${el.start} - ${el.end} (${el.totalHours} hrs)`}
                                  </Typography>
                                  {el.selectedAddons?.length > 0 && (
                                    <Box>
                                      {el.selectedAddons.map((add, iddx) => (
                                        <Typography
                                          key={iddx}
                                          sx={{ mb: 0, fontWeight: 400 }}
                                          color="black"
                                        >
                                          {`${add.quantity} x ${add.addonName}`}
                                        </Typography>
                                      ))}
                                    </Box>
                                  )}

                                  <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    width="auto"
                                  >
                                    <Typography sx={{ mr: 2 }}>
                                      ${el.totalAmount}
                                    </Typography>
                                    <IconButton>
                                      <ClearIcon />
                                    </IconButton>
                                  </Box>
                                </Box>
                              </Box>
                            ))}
                            <Box sx={{ width: "100%" }}>
                              <Divider sx={{ marginBottom: 2 }} />
                              <TextField
                                fullWidth
                                placeholder="Enter your special request here. We'll do our best to accommodate"
                                variant="standard"
                                size="small"
                                sx={{
                                  "& .MuiInputBase-root": {
                                    border: "none",
                                    "&::before": {
                                      borderBottom: "none", // Remove the underline
                                    },
                                    "&::after": {
                                      borderBottom: "none", // Remove the underline
                                    },
                                    "&:hover::before": {
                                      borderBottom: "none", // Remove the underline on hover
                                    },
                                    "&.Mui-focused::before": {
                                      borderBottom: "none", // Remove the underline when focused
                                    },
                                  },
                                  "& .MuiInputLabel-root": {
                                    color: "black", // Customize label color
                                  },
                                  mb: 2, // Margin bottom to space out from Divider
                                }}
                              />
                              <Divider />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  ))}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box sx={{ backgroundColor: "white", padding: 2 }}>
                  <Grid container>
                    <Grid item xs={12} sm={6}>
                      {" Passes"}
                      <Autocomplete
                        disablePortal
                        options={passesData}
                        size="small"
                        onChange={(event, newValue) =>
                          handleChangeDiscount(newValue)
                        }
                        renderInput={(params) => <TextField {...params} />}
                        getOptionLabel={(option: any) => option?.entitled}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      display={"flex"}
                      justifyContent={"center"}
                    >
                      <Stack spacing={0} direction="column" alignItems="center">
                        <Typography
                          variant="h6"
                          sx={{ fontWeight: "normal" }}
                          color={"#333333"}
                          letterSpacing={1}
                        >
                          Discount{" "}
                        </Typography>
                        <Typography variant="h6">
                          ${selectedRange.totalDiscount}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </>
          )}

          <Grid item xs={12} md={12}>
            <Box sx={{}} p={2}>

              <Elements options={options} stripe={stripePromise}>
                <PaymentForm
                  totalPrice={selectedRange.subTotalAmount}
                  addOnsList={addOnsList}
                  selectedRange={selectedRange}
                  passId={passId}
                  passesData={passesData}
                  productId={productId}
                  selectedUnits={selectedUnits}
                  main_widget_color={main_widget_color}
                  extraData={extraData}
                  styleOne={stylesOne}
                  stylesTwo={stylesTwo}
                  stylesThree={stylesThree}
                 
                  setIsBookingSuccess={setIsBookingSuccess}
                />

              </Elements>
              {/* // @ts-ignore */}
            </Box>
          </Grid>

        </Grid>}

    </>
  );
};

export default PassesStep;
