import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
} from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { formatDateString } from "../Pages/core/_utils";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useState } from "react";

const SummaryCard = ({
  selectedRange,
  handleTimeRangeClick,
  selectedTimeRange,
  main_widget_color
}) => {
  const [expandedState, setExpanded] = useState<string | boolean>(true);

  const handleAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(!expandedState);
    };

  return (
    <Box>
      {selectedRange?.reservation?.map((element, index) => (
        <Accordion
          key={element.day}
          // @ts-ignore
          expanded={expandedState}
          onChange={handleAccordionChange(`panel${index}`)}
          sx={{
            marginBottom: "1em",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
            padding: "1em",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            cursor: "pointer",
            backgroundColor: "white",
          }}
        >
          <AccordionSummary
            expandIcon={
              expandedState ? (
                <RemoveCircleIcon sx={{ marginRight: 1 }} />
              ) : (
                <AddCircleIcon sx={{ marginRight: 1 }} />
              )
            }
            aria-controls={`panel${index}-content`}
            id={`panel${index}-header`}
            sx={{
              fontSize: 16,
              fontWeight: 600,
              color: "#505050",
              padding: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row-reverse",
              "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
                transform: "rotate(0deg)",
              },
            }}
          >
            <Typography fontWeight={"550"}>
              {formatDateString(element.day)}
            </Typography>
          </AccordionSummary>
          {element.range.map((el, idx) => (
            <AccordionDetails
              key={idx}
              onClick={() => handleTimeRangeClick(el, index, idx)}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: main_widget_color !== null ? main_widget_color : 'var(--primary-color)',
                  color: "white",
                },
                backgroundColor:
                  el.start === selectedTimeRange?.range.start &&
                  el.end === selectedTimeRange?.range.end
                    ? main_widget_color !== null ? main_widget_color : 'var(--primary-color)'
                    : "white",
                color:
                  el.start === selectedTimeRange?.range.start &&
                  el.end === selectedTimeRange?.range.end
                    ? "white"
                    : "inherit",
                width: "100%",
              }}
            >
              <Typography>
                {el.start} - {el.end}
              </Typography>
            </AccordionDetails>
          ))}
        </Accordion>
      ))}
    </Box>
  );
};

export default SummaryCard;
