import { useState } from "react";
import {
  Autocomplete,
  Box,
  Divider,
  Grid,
  Stack,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import SummaryCard from "./leftpanel";
import { generateOptions } from "../Pages/core/_utils";
import { selectedRangeProps } from "../Pages/core/_models";
import TotalCard from "../components/total-Card";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const AddOnsSelection = ({
  selectedRange,
  addOnsList,
  setSelectedRange,
  resetSelectedRange,
  main_widget_color
}) => {
  const [selectedTimeRange, setSelectedTimeRange] = useState({
    range: selectedRange?.reservation[0].range[0],
    reservationIndex: 0,
    rangeIndex: 0,
  });

  const handleTimeRangeClick = (range, parentIndex, subChildIndex) => {
    setSelectedTimeRange({
      range: range,
      reservationIndex: parentIndex,
      rangeIndex: subChildIndex,
    });
  };

  const handleChangeAddons = (e, range, selectedAddon) => {
    const quantity = Number(e.target.value);
    const { reservationIndex, rangeIndex } = range;

    setSelectedRange((prev) => {
      // Clone current state
      const updatedReservation = [...(prev as selectedRangeProps).reservation];
      const updatedRange = [...updatedReservation[reservationIndex].range];
      let updatedSelectedAddons = [...updatedRange[rangeIndex].selectedAddons];

      // Create new addon object
      const newSelectedAddon = {
        id: selectedAddon.id,
        addonName: selectedAddon.entitled, // Use entitled here
        quantity: quantity,
        price: selectedAddon.price,
        totalPrice: quantity * selectedAddon.price,
      };

      // Find the index of the selected addon
      const addonIndex = updatedSelectedAddons.findIndex(
        (addon) => addon.id === newSelectedAddon.id,
      );

      // Update addons list based on quantity
      if (quantity === 0) {
        if (addonIndex !== -1) {
          updatedSelectedAddons.splice(addonIndex, 1); // Remove addon if quantity is 0
        }
      } else {
        if (addonIndex !== -1) {
          updatedSelectedAddons[addonIndex] = newSelectedAddon; // Update existing addon
        } else {
          updatedSelectedAddons.push(newSelectedAddon); // Add new addon
        }
      }

      // Calculate the totalAddonsPrice for the range
      const totalAddonsPriceForRange = updatedSelectedAddons.reduce(
        (sum, addon) => sum + addon.totalPrice,
        0,
      );

      // Update the range in the reservation
      updatedRange[rangeIndex] = {
        ...updatedRange[rangeIndex],
        selectedAddons: updatedSelectedAddons,
        totalAddonsPrice: totalAddonsPriceForRange,
        totalAmount:
          updatedRange[rangeIndex].totalHoursPrice + totalAddonsPriceForRange, // Ensure totalAmount includes both
        subTotalAmount:
          updatedRange[rangeIndex].totalHoursPrice + totalAddonsPriceForRange, // Ensure subTotalAmount includes both
      };

      // Update the reservation
      updatedReservation[reservationIndex] = {
        ...updatedReservation[reservationIndex],
        range: updatedRange,
        totalAddonsPrice: updatedRange.reduce(
          (sum, r) => sum + r.totalAddonsPrice,
          0,
        ),
        totalAmount: updatedRange.reduce((sum, r) => sum + r.totalAmount, 0),
        subTotalAmount: updatedRange.reduce(
          (sum, r) => sum + r.subTotalAmount,
          0,
        ), // Ensure subTotalAmount is sum of totalAmount for ranges
      };

      // Calculate totalAddonsPrice for the entire selectedRange
      const newTotalAddonsPrice = updatedReservation.reduce(
        (total, reservation) => total + reservation.totalAddonsPrice,
        0,
      );

      // Calculate subTotalAmount for the entire selectedRange
      const newSubTotalAmount = updatedReservation.reduce(
        (total, reservation) => total + reservation.subTotalAmount,
        0,
      );

      // Return updated state
      return {
        ...prev,
        reservation: updatedReservation,
        totalAddonsPrice: newTotalAddonsPrice,
        subTotalAmount: newSubTotalAmount,
        totalAmount: newSubTotalAmount, // Total amount should be subTotalAmount (or adjusted as needed)
      };
    });
  };
  return (
    <Grid container rowGap={3} columnSpacing={6}>
      <Grid item xs={12} sm={4}>
        <SummaryCard
          selectedRange={selectedRange}
          handleTimeRangeClick={handleTimeRangeClick}
          selectedTimeRange={selectedTimeRange}
          main_widget_color={main_widget_color}
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Box sx={{ backgroundColor: "white" }}>
        <Stack spacing={0}>
              <Item
                sx={{
                  p: 2,
                }}
              >
                <Typography sx={{ textAlign: "start" }} noWrap>
                  QTY
                </Typography>
              </Item>
              {addOnsList.map((element) => (
                <div key={element.id}>
                  <Item
                    sx={{
                      p: 2,
                    }}
                  >
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Autocomplete
                        size="small"
                        disablePortal
                        id={`autocomplete-${element.id}`}
                        options={generateOptions(element.available_unit)}
                        sx={{ width: 100 }}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={(event, newValue) =>
                          handleChangeAddons(
                            { target: { value: newValue } },
                            selectedTimeRange,
                            element,
                          )
                        }
                        value={
                          selectedRange.reservation[
                            selectedTimeRange?.reservationIndex
                          ]?.range[
                            selectedTimeRange?.rangeIndex
                          ].selectedAddons.find(
                            (addon) => addon.id === element.id,
                          )?.quantity || 0
                        }
                        getOptionLabel={(option) => option.toString()}
                      />
                      <Typography noWrap>
                        {element.entitled} - {element.price}/booking
                      </Typography>
                    </Stack>
                  </Item>
                  <Divider variant="middle" />
                </div>
              ))}
            </Stack>
        </Box>
      </Grid>
      <Grid item xs={12} sm={8}></Grid>
      <Grid item xs={12} sm={4}>
        <TotalCard
          totalAmount={selectedRange.totalAmount}
          resetSelectedRange={resetSelectedRange}
        />
      </Grid>
    </Grid>
  );
};

export default AddOnsSelection;
