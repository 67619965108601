import { Box, IconButton, Typography } from "@mui/material";
import { ClearIcon } from "@mui/x-date-pickers";

const TotalCard = ({ totalAmount, resetSelectedRange,stylesThree }:any) => {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        ...stylesThree
      }}
      display='flex'
      alignItems="center"
      justifyContent='space-between'

      p={1}
    >
      <Typography sx={{ textAlign: "start" }} noWrap>
        Total
      </Typography>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={"flex-end"}
        width="auto"
      >
        <Typography sx={{ textAlign: "end" }} noWrap>
          ${totalAmount}
        </Typography>
        <IconButton
          onClick={(e) => {
            resetSelectedRange();
          }}
        >
          <ClearIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default TotalCard;
