import { Box, Button, Grid, Link, TextField } from "@mui/material";
import { useFormik } from "formik";

import * as yup from 'yup'



const yupSchema = yup.object({
  first_name: yup.string().required('First Name is required'),
  last_name: yup.string().required('Last Name is required'),
  email: yup
    .string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  phone_number: yup.string(),
  password: yup.string().required('Password is required'),
  repeat_password: yup.string().required('Repeat Password is required'),
});


const SignUpForm = ({
  signUpForm,
  handleSignUpChange,
  handleSignupUser,
  goToLogin,
  main_widget_color,
  formErrors,
  productId
}) => {
  const handleSubmit = () => {

  }

  
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      password: "",
      repeat_password: "",
    },
    validationSchema: yupSchema,
    onSubmit: handleSubmit,
  });


  function renderArrayMultiline(arr = []) {
    if (!arr || !Array.isArray(arr)) {
      return
    }
  
    return arr?.map(e => {
      return (
        <>
          {e}
          <br />
        </>
      )
    })
  }
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "start",
        // height: "100vh", // To vertically center in the viewport
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3} width={400}>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="First Name"
              value={signUpForm.first_name}
              variant={"outlined"}
              sx={{ backgroundColor: "white" }}
              onChange={(e) => {
                handleSignUpChange("first_name", e);
              }}
              error={formErrors?.first_name}
              helperText={renderArrayMultiline(formErrors?.first_name)}
            />
            {/* <TextField
              fullWidth
              label="First Name"
              size="small"
              name="first_name"
              value={formik.values.first_name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.first_name && Boolean(formik.errors.first_name)}
              helperText={formik.touched.first_name && formik.errors.first_name}
              sx={{ backgroundColor: "white" }}
            /> */}
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Last Name"
              value={signUpForm.last_name}
              variant={"outlined"}
              sx={{ backgroundColor: "white" }}
              onChange={(e) => {
                handleSignUpChange("last_name", e);
              }}
              error={formErrors?.last_name}
              helperText={renderArrayMultiline(formErrors?.last_name)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Email"
              value={signUpForm.email}
              variant={"outlined"}
              sx={{ backgroundColor: "white" }}
              onChange={(e) => {
                handleSignUpChange("email", e);
              }}
              error={formErrors?.email}
              helperText={renderArrayMultiline(formErrors?.email)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Phone Number"
              value={signUpForm.phone_number}
              variant={"outlined"}
              sx={{ backgroundColor: "white" }}
              onChange={(e) => {
                handleSignUpChange("phone_number", e);
              }}
              error={formErrors?.phone_number}
              helperText={renderArrayMultiline(formErrors?.phone_number)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              label="Password"
               type="password"
              value={signUpForm.password}
              onChange={(e) => {
                handleSignUpChange("password", e);
              }}
              variant={"outlined"}
              sx={{ backgroundColor: "white" }}
              error={formErrors?.password}
              helperText={renderArrayMultiline(formErrors?.password)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextField
              fullWidth
              type="password"
              label="Repeat Password"
              value={signUpForm.repeat_password}
              onChange={(e) => {
                handleSignUpChange("repeat_password", e);
              }}
              variant={"outlined"}
              sx={{ backgroundColor: "white" }}
              error={formErrors?.repeat_password}
              helperText={renderArrayMultiline(formErrors?.repeat_password)}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button variant="contained" sx={{
              backgroundColor: main_widget_color !== null ? main_widget_color : 'var(--primary-color)',
              '&:hover': {
                backgroundColor: main_widget_color !== null ? main_widget_color : 'var(--primary-color)', // Même couleur au survol
              },
            }} fullWidth onClick={handleSignupUser}>
              Submit
            </Button>
          </Grid>
          {" "}
          {/* Adjust maxWidth as needed */}

          <Grid item xs={12} sm={12} display={"flex"} justifyContent={"center"}>
            <Link onClick={goToLogin} sx={{ cursor: "pointer" }}>
              Go To Sign In Page
            </Link>
          </Grid>
        </Grid>
      </form>

    </Box>
  );
};
export default SignUpForm;
